.container{
    padding: 60px;
    background-color: #05004E;
	position: relative;
}
.textHeader{
    font-family: 'Montserrat' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
}
.mainContainer{
	width: 70%;
	margin: auto;
}
.headContainer{
	display: flex;
	justify-content: space-evenly;
}
.textHeader{
	display: flex;
	justify-content: center;
	margin: auto;
	margin-bottom: 20px;

}
.closeButton{
	display: flex;
	color: #fff;
	background-color: #fff;
	justify-content : flex-end;
}
.cardContainer {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
	grid-gap: 3rem;
	padding-bottom: 50px;
}

.cardHeader {
	display: flex;
	justify-content: center;
	align-items: center;
}

.cardHeading {
	text-align: center;
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 50px;
	color: #0c0c36;
}

.cardEditIcon {
	margin-top: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	background: #ffffff;
	color: #0c0c36;
	box-shadow: inset 4px 8px 6px rgba(0, 0, 0, 0.25);
}

.editIcons {
	width: 34.05px;
	height: 34.05px;
	color: #0c0c36;
}

.cardBody {
	margin: 0 8px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	text-align: center;
	color: rgba(12, 12, 54, 0.7);
}
.slogan{
    margin-top: 40px; 
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 500;
    font-size: 24px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
}