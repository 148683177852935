.container {
	/* padding-left: 5%;
	padding-right: 5%; */
	padding-bottom: 10px;
	filter: drop-shadow(4px 8px 8px rgba(0, 0, 0, 0.25));
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: auto auto;
}

.container span {
   margin-top: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.container span:nth-child(2) {
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	text-align: center;
	color: #0c0c36;
}

@media screen and (max-width: 500px) {
	.container {
		/* padding-left: 2.5%;
		padding-right: 2.5%; */
		text-align: center;
	}
}