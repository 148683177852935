.container{
    width: 100%;
    padding: 80px;
    /* height: 400px; */
    background-color: #05004e;
}
.contactBorder{
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #05004e;
    border: 0.5px solid rgb(126, 126, 126);
	box-shadow: 4px 8px 8px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
}

.contactHeading{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #FFFFFF;
    text-align: center;
    margin: 20px 0px;
}
.contactHead{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #FFFFFF;
    text-align: center;
    margin: 20px 0px;
}
.infoControl {
	display: grid;
	grid-template-columns: 70px 340px;
	margin: 0 auto 20px;
	width: 400px;
	height: 52px;
	background: #fff;
	border: 1px solid #05004e;
	border-radius: 10px;
}
.infoController{
    margin: 0 auto 20px;
	width: 400px;
	height: 52px;
}
.icons,
.iconDetails {
	margin: auto;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: rgba(12, 12, 54, 0.8);
	background: transparent;
}

.iconDetails {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}
.form {
	display: flex;
	flex-direction: column;
	/* align-items: center; */
}

.formInput,
.formTextArea {
	width: 400px;
	background: rgba(255, 255, 255, 0.8);
	border: 1px solid #0c0c36;
	border-radius: 50px;
}
.formInput input {
	width: 100%;
	border: none;
	outline: none;
	border-radius: 10px;
	padding: 20px 10px;
	border: none;
	/* background: transparent; */
	resize: none;
	outline: none;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	/* font-size: 18px; */
	font-size: 16px;
	line-height: 22px;
	color: rgba(12, 12, 54, 0.8);
}

.formInput input::placeholder {
	text-align: center;
	color: rgba(12, 12, 54, 0.8);
}

.formTextArea {
	/* margin-top: 20px; */
    margin: 20px auto 20px;

	height: 280px;
}

.recaptcha {
	margin: 20px auto;
	/* width: 400px; */
	height: 52px;
}

.formTextArea textarea {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	/* font-size: 18px; */
	font-size: 16px;
	line-height: 22px;
	width: 100%;
	height: 300px;
	outline: none;
	/* background: transparent; */
	border-radius: 10px;
	text-align: justify;
	padding-top: 50px;
	padding-bottom: 50px;
	border: none;
	resize: none;
}

.formTextArea textarea::placeholder {
	text-align: center;
	padding-top: 70px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: rgba(12, 12, 54, 0.5);
}

.formButton {
	width: 190px;
	margin: 20px auto;
	/* background: rgba(255, 255, 255, 0.8); */
	border: 1px solid #05004e;
	border-radius: 50px;
	transition: 0.7s;
}

.formButton:hover {
	color: #0c0c36;
	background-color: rgba(255, 255, 255, 0.8);
}

.formButton input {
	width: 100%;
	border-radius: 10px;
	padding: 5px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	/* font-size: 18px; */
	font-size: 16px;
	/* line-height: 22px; */
	/* color: #0c0c36; */
	background-color: #fff;
	color: #05004e;
}
.formButton input:hover {
	color: #fff;
	background-color: #05004e;
	border: 2px solid #fff;
}

@media screen and ( max-width: 420px){
	.container{
		padding: 10px;
	}
	.infoControl{
		width: 350px;
		margin: 20px 0px 0px 5px;
	}
	.formInput,
	.formTextArea {
		width: 350px;
		margin: 20px 0px 0px 5px;
	}
	.formButton {
		margin-top: 30px;
		margin-left: 30%;
	}
	
}



@media screen and (max-width: 840px) {
	.contactBorder {
		grid-template-columns: 1fr;
	}
}

/* @media screen and (max-width: 920px){
	.contactBorder{
		grid-gap: 30px;
	}
	.infoControl{
		width: 350px;
		margin: 20px 0px 0px 5px;
	}
	.formInput,
	.formTextArea {
		width: 350px;
		margin: 20px 0px 0px 5px;
	}
} */

@media screen and (max-width: 930px){
	.container{
		padding: 0px;
	}
	.infoController{
		width: 100%;
	}
	.formButton {
		margin: 5px auto;
	}
}

@media screen and (max-width: 990px) {
	.infoControl,
	.formInput,
	.formTextArea{
		width: 80%;
		margin: 20px auto;
	}
	.formButton {
		margin: 5px auto;
	}
}



@media screen and (max-width: 770px) {
	.container{
		padding: 80px;
	}
	.infoControl,
	.formInput,
	.formTextArea{
		width: 450px;
		margin: 20px auto;
	}
	.formButton {
		margin: 5px auto;
	}
	
}

@media screen and (max-width: 620px) {
	.container{
		padding: 20px;
	}
	.formButton {
		margin: 5px auto;
	}
}

@media screen and (max-width: 485px) {

	.container{
		padding: 10px;
	}
	.formButton {
		margin: 5px auto;
	}
}

@media screen and (max-width: 440px) {
	.infoControl,
	.formInput,
	.formTextArea{
		width: 380px;
		margin: 20px auto;
	}
	.formButton {
		margin: 5px auto;
	}
}

@media screen and (max-width: 400px) {
	.infoControl,
	.formInput,
	.formTextArea{
		width: 350px;
		margin: 20px auto;
	}
	.formButton {
		margin: 5px auto;
	}
}

@media screen and (max-width: 370px) {
	.infoControl,
	.formInput,
	.formTextArea{
		width: 320px;
		margin: 20px auto;
	}
	.formButton {
		margin: 5px auto;
	}
}

@media screen and (max-width: 350px) {
	.infoControl,
	.formInput,
	.formTextArea{
		width: 300px;
		margin: 20px auto;
	}
	.formButton {
		margin: 5px auto;
	}
}

.successContainer{
	padding: 20px;
	background-color: rgb(5, 66, 5);
	border-radius: 20px;
}
.success{
	color: white;
	text-align: center;
	font-size: 24px;
	font-weight: bold;
}