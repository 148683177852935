.imgContainer{
    width: 100%;
}
.expertImage{
    width:100vw;
}
.textContainer{
    padding: 80px;
    padding-left: 160px;
    padding-right: 160px;
    background-color: #3C4251;

}
.paragraph{
    font-family: 'Montserrat' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    color: #FFFFFF;
}
.buttonContainer{
    text-align: center;
}
.buttonLg {
	background-color: #ffffff;
	color: #05004E;
	height: 50px;
	border: none;
	border-radius: 6px;
	font-size: 16px;
	line-height: 16px;
	font-weight: 500;
	text-align: center;
	width: 250px;
	cursor: pointer;
	transition: 0.7s;
}

.buttonLg:hover {
	background-color: #05004E;
	color: #fff;
	border: 2px solid #fff;
}

@media screen and (max-width: 480px) {
	.textContainer{
		/* padding: 80px; */
		padding-left: 20px;
		padding-right: 20px;
	}
	/* .paragraph{
		margin-left: -130px;
		width: 300px;
	} */
}

@media screen and (max-width: 770px) {
	.textContainer{
		padding: 40px;
	}
}

@media screen and (max-width: 1099px) {
	.textContainer{
		padding: 10px;
	}
}