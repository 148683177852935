.container {
	padding: 40px;
	background-color: #05004E;
	position: relative;
}

.containerBlock{
    display: flex;
}
.headContainer{
	display: flex;
	justify-content: space-evenly;
}
.closeButton{
	display: flex;
	color: #fff;
	background-color: #fff;
	justify-content : flex-end;
}
.mainHeader{
	display: flex;
	justify-content: center;
	margin: auto;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 20px;
}
.contactLeft {
	flex: 1;
	display: flex;
	flex-direction: column;
	text-align: center;
	/* padding-bottom: 50px; */
    padding: 10px 60px 50px 60px;
}
.contactHeading{
    font-family: 'Montserrat' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
}


.form {
	display: flex;
	flex-direction: column;
	/* align-items: center; */
}

.formInput input {
	width: 100%;
	border: none;
	outline: none;
    margin-top: 20px;
	border-radius: 10px;
	padding: 20px 10px;
	border: none;
	/* background: transparent; */
	resize: none;
	outline: none;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	/* font-size: 18px; */
	font-size: 16px;
	line-height: 22px;
	color: rgba(12, 12, 54, 0.8);
}

.recaptcha {
	margin: 20px auto;
	/* width: 400px; */
	height: 52px;
}

.formTextArea {
	margin-top: 20px;
	height: 100px;
}

.formTextArea textarea {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	/* font-size: 18px; */
	font-size: 16px;
	/* line-height: 22px; */
	width: 100%;
	height: 100px;
	outline: none;
	/* background: transparent; */
	border-radius: 10px;
	/* text-align: justify; */
	padding-top: 50px;
	padding-bottom: 50px;
	border: none;
	resize: none;
    overflow: hidden;
}

.formButton {
	width: 190px;
	/* background: rgba(255, 255, 255, 0.8); */
	border: 1px solid #05004E;
	border-radius: 50px;
	transition: 0.7s;
	margin: 40px auto 20px;
}

/* .formButton:hover{
	color: #0c0c36;
	background-color: rgba(255, 255, 255, 0.8);
} */

.formButton input {
	width: 100%;
	border-radius: 10px;
	padding: 5px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	background-color: #fff;
    text-align: center;
	color: #05004E;
}
.formButton input:hover{
	color: #fff;
	background-color: #05004E;
	border: 2px solid #fff;
}

.contactRight {
	flex: 2;
	/* display: flex;
	flex-direction: column;
	text-align: center;
	padding-bottom: 50px; */
}
.consultantImage{
    border-radius: 10px;
}
.modalMessage{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
}
.slogan{
    margin-top: 40px; 
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 500;
    font-size: 24px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
}


@media screen and (max-width: 420px){
	.mainHeader{
		font-size: 32px;
		line-height: 40px;
	}
	.formInput input,
	.formTextArea textarea  {
		width: 360px;
		margin-left: 0px;

	}
	.formButton input{
		margin-left: 1%;
	}
}
@media screen and (max-width: 450px){
	.contactLeft {
		padding: 0px;
	}

}
@media screen and (max-width: 400px){
	.container{
		padding: 15px;
	}
	.mainHeader{
		font-size: 32px;
		line-height: 40px;
	}
	.contactLeft {
		padding: 10px 0px 0px 0px;
	}
	.formInput input,
	.formTextArea textarea  {
		width: 300px;
		margin-left: 0px;
	}
	.modalMessage{
		margin-top: 25px;
	}
}

@media screen and (max-width: 840px){
	.containerBlock{
		display: flex;
		flex-direction: column;
	}
	.modalMessage{
		margin-top: 25px;
	}
}