.container {
	/* padding-left: 5%;
	padding-right: 5%;
	text-align: center; */
	width: 100%;
	padding: 70px;
	text-align: center;
	background-color: #05004E;
	color: #fff;

}

.h1 {
	font-family: Montserrat !important;
	font-style: normal;
	font-weight: 600;
	font-size: 48px;
	line-height: 60px;
	text-align: center;
	color: #fff;
	padding-top: 70px;
}

.paragraph {
	padding-left: 15%;
	padding-right: 15%;
	margin: 30px auto 50px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 40px;
	text-align: center;
	color: #fff;
}
.buttonLg {
	background: #ffffff;
	color: #05004E;
	height: 50px;
	border: none;
	border-radius: 6px;
	font-size: 16px;
	line-height: 16px;
	font-weight: 500;
	text-align: center;
	width: 250px;
	cursor: pointer;
	transition: 0.7s;
}

.buttonLg:hover {
	background-color: #05004E;
	color: #fff;
	border: 2px solid #fff;
}

.cardContainer {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
	grid-gap: 1rem;
	padding-bottom: 50px;
}

.cardHeader {
	display: flex;
	justify-content: center;
	align-items: center;
}

.cardHeading {
	text-align: center;
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 50px;
	color: #0c0c36;
}

.cardEditIcon {
	margin-top: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	background: #ffffff;
	color: #0c0c36;
	box-shadow: inset 4px 8px 6px rgba(0, 0, 0, 0.25);
}

.editIcons {
	width: 34.05px;
	height: 34.05px;
	color: #0c0c36;
}

.cardBody {
	margin: 0 8px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	text-align: center;
	color: rgba(12, 12, 54, 0.7);
}
.buttonLink{
	text-decoration: none;
	scroll-behavior: smooth;
	color: rgba(12, 12, 54, 0.7);
}
.buttonLink:hover{
	text-decoration: none;
	color: #fff;
}
@media screen and (max-width: 1099px) {
	.paragraph{
		padding: 0px;
	}
}

@media screen and (max-width: 770px) {
	.paragraph{
		padding: 0px;
	}
}
@media screen and (max-width: 720px) {
	.paragraph {
		padding-left: 5%;
		padding-right: 5%;
	}
}

@media screen and (max-width: 700px) {
	.h1 {
		font-size: 4rem;
	}
}

@media screen and (max-width: 500px) {
	.container {
		padding-left: 2.5%;
		padding-right: 2.5%;
		text-align: center;
	}

	.paragraph {
		padding-left: 2.5%;
		padding-right: 2.5%;
	}
}
