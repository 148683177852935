.imgContainer{
    width: 100%;
}
.detailsImage{
    width:100vw;
}
.wrapped{
    background: linear-gradient(91.75deg, #E5E7E8 -53.92%, #E5E6E8 84.42%);
}
.h1{
    font-family: 'Montserrat' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    color: #05004E;
}
.textContainer{
    padding: 50px;
    background: linear-gradient(91.75deg, #E5E7E8 -53.92%, #E5E6E8 84.42%);

}
.buttonContainer{
    text-align: center;
}
.buttonLg {
	color: #ffffff;
	background: #05004E;
	height: 50px;
	border: none;
	border-radius: 6px;
	font-size: 16px;
	line-height: 16px;
	font-weight: 500;
	text-align: center;
	width: 250px;
	cursor: pointer;
	transition: 0.7s;
}

.buttonLg:hover {
	color: #05004E;
	background-color: #fff;
	border: 2px solid #05004E;
}
.parallel{
    width: 100%;
    height: 150px;
    background-color: #05004E;
    transform: rotate(1deg);
}