.container {
	/* padding-left: 5%;
	padding-right: 5%;
	text-align: center; */
	width: 100%;
	padding: 70px;
	text-align: center;
	background-color: #05004E;
	color: #fff;
}

.h1 {
	font-family: 'Montserrat' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    color: #05004E;
}

@media screen and (max-width: 700px) {
	.h1 {
		font-size: 3rem;
	}
}

.h2 {
	font-family: Montserrat !important;
	font-style: normal;
	font-weight: 600;
	font-size: 48px;
	line-height: 60px;
	text-align: center;
	color: #fff;
}

.paragraph {
	/* width: 800px;
	margin: 30px auto 50px; */
	padding: 50px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	color: #fff;
}

.textContainer{
    padding: 50px;
    background: linear-gradient(91.75deg, #E5E7E8 -53.92%, #E5E6E8 84.42%);

}

.cardContainer {
	display: grid;
	/* grid-template-columns: repeat(4, 1fr); */
	grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
	row-gap: 20px;
	column-gap: 30px;
	padding-bottom: 50px;
}

@media screen and (max-width: 480px) {
	.container{
		padding: 10px;
	}
	.cardContainer {
		width: 80%;
		margin: auto;
	}
	.h1{
		font-size: 3rem;
	}
}

@media screen and (max-width: 1050px) {
	.cardContainer {
		display: grid;
		/* grid-template-columns: repeat(3, 1fr); */
		padding-bottom: 50px;
	}
}

@media screen and (max-width: 950px) {
	.cardContainer {
		display: grid;
		/* grid-template-columns: repeat(3, 1fr); */
		column-gap: 20px;
		padding-bottom: 50px;
	}
}

.cardHeader {
	display: flex;
	justify-content: center;
	align-items: center;
}

.cardHeading {
	text-align: center;
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 50px;
	color: #0c0c36;
}

.cardEditIcon {
	margin-top: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	background: #ffffff;
	box-shadow: inset 4px 8px 6px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 720px) {
	.paragraph {
		padding-left: 5%;
		padding-right: 5%;
	}
}

@media screen and (max-width: 500px) {
	.paragraph {
		padding-left: 2.5%;
		padding-right: 2.5%;
	}
}
