.container {
	padding-top: 2rem;
	display: grid;
	align-items: center;
	/* grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); */
	grid-template-columns: 480px 800px;
	padding-left: 5.5%;
	padding-right: 5.5%;
}

.heroLeft {
	padding-bottom: 190px;
	margin-top: 100px;
}

.heading2 {
	/* width: 383px; */
	/* inline-size: 55%; */
	inline-size: 70%;
	overflow-wrap: break-word;
	font-family: Merriweather !important;
	font-style: normal;
	font-weight: 700;
	font-size: 32px;
	line-height: 46px;
	color: #05004E;
}

.paragraph {
	font-family: "Merriweather", serif;
	color: #05004E;
	inline-size: 80%;
	overflow-wrap: break-word;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 40px;
}

.buttonContainer {
	padding-top: 10%;
}

.button {
	color: #ffffff;
	background: #05004E;
	height: 50px;
	border: none;
	border-radius: 6px;
	font-size: 16px;
	line-height: 16px;
	font-weight: 500;
	text-align: center;
	width: 160px;
	cursor: pointer;
	transition: 0.7s;
}

.button:hover {
	color: #0c0c36;
	background-color: rgba(12, 12, 54, 0.13);
	border: 0.5px solid #0c0c36;
}

.buttonLg {
	color: #ffffff;
	background: #05004E;
	height: 50px;
	border: none;
	border-radius: 6px;
	font-size: 16px;
	line-height: 16px;
	font-weight: 500;
	text-align: center;
	width: 250px;
	cursor: pointer;
	transition: 0.7s;
}

.buttonLg:hover {
	color: #05004E;
	background-color: #fff;
	border: 2px solid #05004E;
}

.shaded {
	color: #0c0c36;
	background: rgba(12, 12, 54, 0.13);
	height: 50px;
	border: 1.5px solid #0c0c36;
	border-radius: 6px;
	font-size: 16px;
	line-height: 16px;
	font-weight: 500;
	text-align: center;
	width: 160px;
	margin-left: 10%;
	cursor: pointer;
	transition: 0.7s;
}

.shaded:hover {
	color: #ffffff;
	background-color: #0c0c36;
	border: 1px solid #ffffff;
}

.heroImage{
	width: 900px;
	height: 535px;
	object-fit: contain;
}

@media screen and (max-width: 1050px) {
	.container {
		padding-top: 5.95rem !important;
		padding-left: 2.5% !important;
		padding-right: 2.5% !important;
	}

	.heroLeft {
		padding-bottom: 90px;
	}
}

@media screen and (max-width: 1000px) {
	.heading2 {
		inline-size: 90% !important;
		overflow-wrap: break-word;
	}

	.paragraph {
		inline-size: 95% !important;
		overflow-wrap: break-word;
	}
}

@media screen and (max-width: 950px) {
	.container {
		padding-top: 8rem !important;
		padding-left: 0.5% !important;
		padding-right: 0.5% !important;
	}
}

@media screen and (max-width: 925px) {
	.heroLeft button,
	.heroRight button {
		width: 140px;
	}
}

@media screen and (max-width: 900px) {
	.heroRight img {
		width: 95%;
		height: auto;
	}
}

@media only screen and (max-width: 950px) and (min-width: 870px)  {
	.heroImage{
		width: 422px;
		height: 535px;
		object-fit: contain;
	}
	
}

@media screen and (max-width: 850px) {
	.container {
		padding-top: 10rem;
		display: grid;
		align-items: center;
		grid-template-columns: 1fr !important;
		padding-left: 15%;
		padding-right: 15%;
	}

	.heroLeft {
		padding-bottom: 10.5%;
		padding-left: 15%;
		padding-right: 15%;
		margin-top: 20px;

	}

	.paragraph {
		font-family: "Merriweather", serif;
		color: rgba(12, 12, 54, 0.75);
		inline-size: 600px;
		overflow-wrap: break-word;
		font-family: Montserrat;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
	}

	.buttonContainer {
		padding-top: 5%;
	}

	.heroRight img {
		height: auto;
		padding-left: 15%;
		padding-right: 15%;
	}
}

@media screen and (max-width: 650px) {
	.heroLeft {
		padding-bottom: 10.5%;
		padding-left: 10%;
		padding-right: 10%;
	}

	.heroRight img {
		height: auto;
		padding-left: 10%;
		padding-right: 10%;
	}
}

@media screen and (max-width: 500px) {
	.heroLeft {
		padding-bottom: 8.5%;
		padding-left: 2.5%;
		padding-right: 2.5%;
	}

	.heroRight img {
		height: auto;
		padding-left: 2.5%;
		padding-right: 2.5%;
	}
}

@media screen and (max-width: 420px) {
	.heroLeft {
		padding-bottom: 6.5%;
		padding-left: 0.5%;
		padding-right: 0.5%;
		margin-top: 20px;
	}

	.heroRight img {
		height: auto;
		padding-left: 0.5%;
		padding-right: 0.5%;
	}
}
