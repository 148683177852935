@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,600&family=Roboto:wght@100;300;400;500;700;900&display=swap");

* {
	box-sizing: border-box;
}

html,
body {
	padding: 0;
	margin: 0;
	scroll-behavior: smooth;
	overflow-x: hidden;
	width: 100%;
}

body {
	font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
	position: relative;
}

img {
	max-width: 100%;
	display: block;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

.navBar {
	height: 120px;
	width: 100vw;
	background-color: transparent;
	position: fixed;
	z-index: 10;
	transition: 0.8s all ease;
}

.modalCloseBtn {
	background: inherit;
	color: white;
	outline: none;
	box-shadow: none;
	border: none;
	font-size: 25px;
	position: absolute;
	top: 20px;
	right: 20px;
}

@media screen and (max-width: 1180px) {
	.navBar {
		height: 110px;
		z-index: 10;
		transition: 0.8s all ease;
	}
}

.navBar.colorChange {
	background-color: whitesmoke !important;
}

.home {
	/* background: rgba(12, 12, 54, 0.13); */
	height: 700px;
	position: relative;
}

@media screen and (max-width: 850px) {
	.home {
		height: 90%;
	}

	.modalCloseBtn {
		position: absolute;
		top: 20px;
		right: 20px;
	}
}

@media screen and (max-width: 950px) {
	body {
		font-family: Roboto, Helvetica, Arial, sans-serif !important;
	}

	.wrapper {
		width: 100vw;
		margin: auto 0;
		padding: 0;
	}
}

@media screen and (min-width: 950px) {
	.wrapper {
		max-width: 1330px;
		margin: auto;
		padding: 0;
	}
}
/* .detail{
	background: linear-gradient(91.75deg, #E5E7E8 -53.92%, #E5E6E8 84.42%);
	
} */
/* .service,
.about,
.product,
.contact {
	background: rgba(12, 12, 54, 0.13);
	margin-top: 15px;
} */

/* .footer {
	background: rgba(12, 12, 54, 0.13);
} */

.navItem,
.nav-item {
	margin-left: 40px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
	color:#05004E;
	;
	cursor: pointer;
}

.navItem > a {
	text-decoration: none;
	color: inherit;
}

.navItem > .active {
	/* color: #0c0c36; */
	border-bottom: 2px solid #0c0c36;
}

.navItem:hover {
	border-bottom: 0.5px solid #0c0c36;
}

/* Mobile view navbar */
/* Icon 1 */

.navbar-brand img {
	width: 165px;
	height: auto;
	padding-left: 2%;
}

.navbar {
	width: 100vw;
	background-color: whitesmoke !important;
	position: fixed !important;
	padding-left: 0.5%;
	padding-right: 0.5%;
	transition: 0.8s all ease;
	z-index: 10;
}

.collapse {
	background-color: whitesmoke;
}

.second-button {
	outline: none;
	box-shadow: none;
	background: inherit;
}

.second-button:focus {
	outline: none;
	box-shadow: none;
	background: inherit;
}

.second-button:active {
	border: none !important;
	outline: none;
	background: inherit;
}

.nav-item > a,
.nav-link > a {
	text-decoration: none !important;
	color: #747497 !important;
}

.nav-link > .active {
	/* color: #0c0c36; */
	border-bottom: 2px solid #0c0c36;
}

/* .nav-item:hover,
.nav-link:hover {
	border-bottom: 0.5px solid #0c0c36 !important;
} */

.navbar-toggler:focus,
.navbar-toggler.btn:focus {
	outline: none !important;
	border: none !important;
	box-shadow: none !important;
}

.animated-icon2 {
	width: 30px;
	height: 20px;
	position: relative;
	margin: 0px;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.5s ease-in-out;
	-moz-transition: 0.5s ease-in-out;
	-o-transition: 0.5s ease-in-out;
	transition: 0.5s ease-in-out;
	cursor: pointer;
}

.animated-icon2 span {
	display: block;
	position: absolute;
	height: 3px;
	width: 100%;
	border-radius: 9px;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.25s ease-in-out;
	-moz-transition: 0.25s ease-in-out;
	-o-transition: 0.25s ease-in-out;
	transition: 0.25s ease-in-out;
}

.animated-icon2 span {
	background: #0c0c36;
}

.animated-icon2 span:nth-child(1) {
	top: 0px;
}

.animated-icon2 span:nth-child(2),
.animated-icon2 span:nth-child(3) {
	top: 10px;
}

.animated-icon2 span:nth-child(4) {
	top: 20px;
}

.animated-icon2.open span:nth-child(1) {
	top: 11px;
	width: 0%;
	left: 50%;
}

.animated-icon2.open span:nth-child(2) {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

.animated-icon2.open span:nth-child(3) {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.animated-icon2.open span:nth-child(4) {
	top: 11px;
	width: 0%;
	left: 50%;
}
