.container {
	display: flex;
	padding-left: 5%;
	padding-right: 5%;
	z-index: 10;
	height: inherit;
	width: inherit;
}

.logo {
	flex: 1;
	padding-top: 10px;
}

.logo img {
	width: 100%;
	height: auto;
}

.nav {
	flex: 3;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	padding: 0;
	list-style: none;
	margin-right: 50px;
} 

@media screen and (max-width: 1050px) {
	.container {
		padding-left: 2.5% !important;
		padding-right: 2.5% !important;
	}
}
