@media screen and (max-width: 420px){
    .customCard{
        padding: 10px;
        width: 340px;
        min-width: 250px;
        background: rgb(255, 255, 255);
        box-shadow: rgb(0 0 0 / 25%) 4px 8px 8px;
        border-radius: 10px;
        margin-left: -25%;
    }
}

@media screen and (max-width: 375px){
    .customCard{
        padding: 5px;
        max-width: 320px;
        background: rgb(255, 255, 255);
        box-shadow: rgb(0 0 0 / 25%) 4px 8px 8px;
        border-radius: 10px;
        margin-left: -25%;
    }
}

@media screen and (max-width: 320px){
    .customCard{
        padding: 5px;
        max-width: 270px;
        background: rgb(255, 255, 255);
        box-shadow: rgb(0 0 0 / 25%) 4px 8px 8px;
        border-radius: 10px;
        margin-left: -25%;
    }
}